import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import ReactCountryFlag from "react-country-flag";
import { APP_SIGNUP_URL } from "data/config";
import { Link, PageProps } from "gatsby";
import { CaseStudyType } from "types";
import { window } from "browser-monads";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Quote } from "components/design-system";
import CaseStudyArticle from "components/design-system/CaseStudyArticle/CaseStudyArticle";
import SEO from "components/SEO";
import { customersPageUrl } from "data/pageUrl";

import * as styles from "./case.module.scss";

interface CaseStudyTemplateProps extends PageProps {
  pageContext: CaseStudyType;
}

const CaseStudyTemplate: React.FC<CaseStudyTemplateProps> = ({
  pageContext: { attributes },
}) => {
  const {
    company_name,
    title,
    excerpt,
    cover_img,
    logo,
    brass_products,
    industry,
    quote,
    speaker,
    featured_img,
    challenge,
    solution,
    results,
    country,
  } = attributes;

  const coverImage = getImage(cover_img.data.attributes.localFile);
  const featuredImage = getImage(featured_img.data.attributes.localFile);
  const companyLogo = getImage(logo.data.attributes.localFile);
  const countryLogo = React.useMemo(
    () =>
      country.data.attributes.isoCode ? country.data.attributes.isoCode : "NG",
    [country.data.attributes.isoCode]
  );

  return (
    <>
      <SEO
        type="article"
        slug={company_name}
        title={title}
        description={excerpt}
        image={cover_img.data.attributes.url}
      />
      <section className={styles.CaseStudyTemplate}>
        <div className={styles.CaseStudyTemplate_container}>
          <header className={styles.CaseStudyTemplate_header}>
            <div>
              <p className={styles.CaseStudyTemplate_header_content_meta}>
                <Link
                  to={customersPageUrl}
                  className={styles.CaseStudyTemplate_header_content_meta_link}
                >
                  Retail customers
                </Link>{" "}
                / {company_name}
              </p>
              <h2 className={styles.CaseStudyTemplate_header_content_title}>
                {title}
              </h2>
              <p className={styles.CaseStudyTemplate_header_content_desc}>
                {excerpt}
              </p>
            </div>
            <GatsbyImage
              image={featuredImage!}
              alt={company_name}
              className={styles.CaseStudyTemplate_header_img}
            />
          </header>
          <div className={styles.CaseStudyTemplate_content}>
            <div className={styles.CaseStudyTemplate_content_container}>
              <div
                className={styles.CaseStudyTemplate_content_container_company}
              >
                <div
                  className={
                    styles.CaseStudyTemplate_content_container_company_top
                  }
                >
                  <div
                    className={
                      styles.CaseStudyTemplate_content_container_company_top_logo
                    }
                  >
                    <GatsbyImage
                      image={companyLogo!}
                      alt={company_name}
                      objectFit="contain"
                      className={
                        styles.CaseStudyTemplate_content_container_company_top_logo_img
                      }
                    />
                  </div>
                  <div
                    className={
                      styles.CaseStudyTemplate_content_container_company_top_products
                    }
                  >
                    <p
                      className={
                        styles.CaseStudyTemplate_content_container_company_top_products_header
                      }
                    >
                      Products used
                    </p>
                    <ul
                      className={
                        styles.CaseStudyTemplate_content_container_company_top_products_list
                      }
                    >
                      {brass_products.data.map((item) => (
                        <li
                          className={
                            styles.CaseStudyTemplate_content_container_company_top_products_list_item
                          }
                          key={item.id}
                        >
                          {item.attributes.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className={
                      styles.CaseStudyTemplate_content_container_company_top_footer
                    }
                  >
                    <div
                      className={
                        styles.CaseStudyTemplate_content_container_company_top_footer_country
                      }
                    >
                      <ReactCountryFlag
                        countryCode={countryLogo}
                        className={
                          styles.CaseStudyTemplate_content_container_company_top_footer_country_img
                        }
                        svg
                        title={countryLogo}
                      />
                      <h3
                        className={
                          styles.CaseStudyTemplate_content_container_company_top_footer_text
                        }
                      >
                        {country.data.attributes.name}
                      </h3>
                    </div>
                    <h3
                      className={
                        styles.CaseStudyTemplate_content_container_company_top_footer_text
                      }
                    >
                      {industry.data.attributes.name}
                    </h3>
                  </div>
                </div>
                <div
                  className={
                    styles.CaseStudyTemplate_content_container_company_bottom
                  }
                >
                  <h3
                    className={
                      styles.CaseStudyTemplate_content_container_company_bottom_text
                    }
                  >
                    Ready to grow your business?
                  </h3>
                  <Button
                    className={
                      styles.CaseStudyTemplate_content_container_company_bottom_btn
                    }
                    variant={ButtonVariant.Primary}
                    onClick={() => window.open(APP_SIGNUP_URL, "_blank")}
                  >
                    Open an account
                  </Button>
                </div>
              </div>

              <div
                className={styles.CaseStudyTemplate_content_container_details}
              >
                <CaseStudyArticle title="Challenge" desc={challenge} />
                <CaseStudyArticle title="Solution" desc={solution} />
                <CaseStudyArticle title="Results" desc={results} />
                <Quote
                  company_name={company_name}
                  quote={quote}
                  speaker={speaker}
                  bg_img={coverImage!}
                  user_img={featuredImage!}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyTemplate;
