// extracted by mini-css-extract-plugin
export var CaseStudyTemplate = "case-module--CaseStudyTemplate--bL4FM";
export var CaseStudyTemplate_container = "case-module--CaseStudyTemplate_container--xBxS6";
export var CaseStudyTemplate_content = "case-module--CaseStudyTemplate_content--ZShBx";
export var CaseStudyTemplate_content_container = "case-module--CaseStudyTemplate_content_container--rPNxt";
export var CaseStudyTemplate_content_container_company = "case-module--CaseStudyTemplate_content_container_company--wDgJ1";
export var CaseStudyTemplate_content_container_company_bottom = "case-module--CaseStudyTemplate_content_container_company_bottom--ADSOP";
export var CaseStudyTemplate_content_container_company_bottom_btn = "case-module--CaseStudyTemplate_content_container_company_bottom_btn--uPWGW";
export var CaseStudyTemplate_content_container_company_bottom_text = "case-module--CaseStudyTemplate_content_container_company_bottom_text--ENMd1";
export var CaseStudyTemplate_content_container_company_top = "case-module--CaseStudyTemplate_content_container_company_top--PRhml";
export var CaseStudyTemplate_content_container_company_top_footer = "case-module--CaseStudyTemplate_content_container_company_top_footer--M8d-H";
export var CaseStudyTemplate_content_container_company_top_footer_country = "case-module--CaseStudyTemplate_content_container_company_top_footer_country--YshjE";
export var CaseStudyTemplate_content_container_company_top_footer_country_img = "case-module--CaseStudyTemplate_content_container_company_top_footer_country_img--886e8";
export var CaseStudyTemplate_content_container_company_top_footer_text = "case-module--CaseStudyTemplate_content_container_company_top_footer_text--VMzv0";
export var CaseStudyTemplate_content_container_company_top_logo = "case-module--CaseStudyTemplate_content_container_company_top_logo--fEOmu";
export var CaseStudyTemplate_content_container_company_top_logo_img = "case-module--CaseStudyTemplate_content_container_company_top_logo_img--LpUd8";
export var CaseStudyTemplate_content_container_company_top_products = "case-module--CaseStudyTemplate_content_container_company_top_products--BEWWA";
export var CaseStudyTemplate_content_container_company_top_products_header = "case-module--CaseStudyTemplate_content_container_company_top_products_header--caoQL";
export var CaseStudyTemplate_content_container_company_top_products_list = "case-module--CaseStudyTemplate_content_container_company_top_products_list--e3bz9";
export var CaseStudyTemplate_content_container_company_top_products_list_item = "case-module--CaseStudyTemplate_content_container_company_top_products_list_item--LnxBr";
export var CaseStudyTemplate_content_container_details = "case-module--CaseStudyTemplate_content_container_details--4fSya";
export var CaseStudyTemplate_header = "case-module--CaseStudyTemplate_header--zvfbN";
export var CaseStudyTemplate_header_content_desc = "case-module--CaseStudyTemplate_header_content_desc--GrSxH";
export var CaseStudyTemplate_header_content_meta = "case-module--CaseStudyTemplate_header_content_meta--Y-Pcl";
export var CaseStudyTemplate_header_content_meta_link = "case-module--CaseStudyTemplate_header_content_meta_link--rNZES";
export var CaseStudyTemplate_header_content_title = "case-module--CaseStudyTemplate_header_content_title--13SZO";
export var CaseStudyTemplate_header_img = "case-module--CaseStudyTemplate_header_img--aDx4k";
export var container = "case-module--container--eUgOw";
export var gatsbyImageWrapperConstrained = "case-module--gatsby-image-wrapper-constrained--VAvB7";
export var gridSpan1010 = "case-module--grid-span-10-10--7rOMl";
export var gridSpan1011 = "case-module--grid-span-10-11--DOzxN";
export var gridSpan102 = "case-module--grid-span-10-2--R8ecz";
export var gridSpan103 = "case-module--grid-span-10-3--kDkYr";
export var gridSpan104 = "case-module--grid-span-10-4--UqkaH";
export var gridSpan105 = "case-module--grid-span-10-5--j5OU1";
export var gridSpan106 = "case-module--grid-span-10-6--hQ+Wd";
export var gridSpan107 = "case-module--grid-span-10-7--ZXpB9";
export var gridSpan108 = "case-module--grid-span-10-8--77ai-";
export var gridSpan109 = "case-module--grid-span-10-9--XINA6";
export var gridSpan110 = "case-module--grid-span-1-10--FZVfy";
export var gridSpan111 = "case-module--grid-span-1-11--mydKr";
export var gridSpan1110 = "case-module--grid-span-11-10--RELtR";
export var gridSpan1111 = "case-module--grid-span-11-11--MqkyP";
export var gridSpan112 = "case-module--grid-span-11-2--zksNE";
export var gridSpan113 = "case-module--grid-span-11-3--0LZaZ";
export var gridSpan114 = "case-module--grid-span-11-4--aoxTH";
export var gridSpan115 = "case-module--grid-span-11-5--i9dkZ";
export var gridSpan116 = "case-module--grid-span-11-6--2wBV5";
export var gridSpan117 = "case-module--grid-span-11-7--bOedY";
export var gridSpan118 = "case-module--grid-span-11-8--3M4+B";
export var gridSpan119 = "case-module--grid-span-11-9--oIy5w";
export var gridSpan12 = "case-module--grid-span-1-2--5BdsR";
export var gridSpan1210 = "case-module--grid-span-12-10--nV-Pk";
export var gridSpan1211 = "case-module--grid-span-12-11--wIB1M";
export var gridSpan122 = "case-module--grid-span-12-2--Ty1RL";
export var gridSpan123 = "case-module--grid-span-12-3--O27tG";
export var gridSpan124 = "case-module--grid-span-12-4--dVRpE";
export var gridSpan125 = "case-module--grid-span-12-5--KxP-4";
export var gridSpan126 = "case-module--grid-span-12-6--Acx-l";
export var gridSpan127 = "case-module--grid-span-12-7--chvIB";
export var gridSpan128 = "case-module--grid-span-12-8--BEwKl";
export var gridSpan129 = "case-module--grid-span-12-9--5LUDq";
export var gridSpan13 = "case-module--grid-span-1-3--852de";
export var gridSpan14 = "case-module--grid-span-1-4--KFcvo";
export var gridSpan15 = "case-module--grid-span-1-5--eDDmn";
export var gridSpan16 = "case-module--grid-span-1-6--a1rhg";
export var gridSpan17 = "case-module--grid-span-1-7--sJCRN";
export var gridSpan18 = "case-module--grid-span-1-8--YZ1yQ";
export var gridSpan19 = "case-module--grid-span-1-9--jnJfu";
export var gridSpan210 = "case-module--grid-span-2-10--hlcpI";
export var gridSpan211 = "case-module--grid-span-2-11--X736D";
export var gridSpan22 = "case-module--grid-span-2-2--J1gib";
export var gridSpan23 = "case-module--grid-span-2-3--xJszW";
export var gridSpan24 = "case-module--grid-span-2-4--jeyGt";
export var gridSpan25 = "case-module--grid-span-2-5--piSji";
export var gridSpan26 = "case-module--grid-span-2-6--6I+Tb";
export var gridSpan27 = "case-module--grid-span-2-7--X8BvQ";
export var gridSpan28 = "case-module--grid-span-2-8--tmL3N";
export var gridSpan29 = "case-module--grid-span-2-9--dPrik";
export var gridSpan310 = "case-module--grid-span-3-10--hNZWf";
export var gridSpan311 = "case-module--grid-span-3-11--JXncb";
export var gridSpan32 = "case-module--grid-span-3-2--wTwiO";
export var gridSpan33 = "case-module--grid-span-3-3--lYSmT";
export var gridSpan34 = "case-module--grid-span-3-4--x+bjc";
export var gridSpan35 = "case-module--grid-span-3-5--c4bTO";
export var gridSpan36 = "case-module--grid-span-3-6--AiCE+";
export var gridSpan37 = "case-module--grid-span-3-7--Nfnrx";
export var gridSpan38 = "case-module--grid-span-3-8--4NOaT";
export var gridSpan39 = "case-module--grid-span-3-9--LazCn";
export var gridSpan410 = "case-module--grid-span-4-10--HSGd4";
export var gridSpan411 = "case-module--grid-span-4-11--wnb4N";
export var gridSpan42 = "case-module--grid-span-4-2--EjqET";
export var gridSpan43 = "case-module--grid-span-4-3--UWu+R";
export var gridSpan44 = "case-module--grid-span-4-4--ix7GZ";
export var gridSpan45 = "case-module--grid-span-4-5--kUCIh";
export var gridSpan46 = "case-module--grid-span-4-6--Jzcup";
export var gridSpan47 = "case-module--grid-span-4-7--PkF1t";
export var gridSpan48 = "case-module--grid-span-4-8--4IPT8";
export var gridSpan49 = "case-module--grid-span-4-9--gtDR4";
export var gridSpan510 = "case-module--grid-span-5-10--Zw0ml";
export var gridSpan511 = "case-module--grid-span-5-11--owaBi";
export var gridSpan52 = "case-module--grid-span-5-2--xXtdq";
export var gridSpan53 = "case-module--grid-span-5-3--qtRBB";
export var gridSpan54 = "case-module--grid-span-5-4--aExMe";
export var gridSpan55 = "case-module--grid-span-5-5--opRY7";
export var gridSpan56 = "case-module--grid-span-5-6--YmmAU";
export var gridSpan57 = "case-module--grid-span-5-7--BFD9t";
export var gridSpan58 = "case-module--grid-span-5-8--SNSPz";
export var gridSpan59 = "case-module--grid-span-5-9--cHZRO";
export var gridSpan610 = "case-module--grid-span-6-10--yq7Wl";
export var gridSpan611 = "case-module--grid-span-6-11--GqSkW";
export var gridSpan62 = "case-module--grid-span-6-2--znEfI";
export var gridSpan63 = "case-module--grid-span-6-3--FbAq2";
export var gridSpan64 = "case-module--grid-span-6-4--FKXCz";
export var gridSpan65 = "case-module--grid-span-6-5--ZzLnB";
export var gridSpan66 = "case-module--grid-span-6-6--we2Lc";
export var gridSpan67 = "case-module--grid-span-6-7--u9E2N";
export var gridSpan68 = "case-module--grid-span-6-8--EZFI0";
export var gridSpan69 = "case-module--grid-span-6-9--lFDeD";
export var gridSpan710 = "case-module--grid-span-7-10--HppzR";
export var gridSpan711 = "case-module--grid-span-7-11--HRLZA";
export var gridSpan72 = "case-module--grid-span-7-2--idsQM";
export var gridSpan73 = "case-module--grid-span-7-3--yLEIS";
export var gridSpan74 = "case-module--grid-span-7-4--O50jT";
export var gridSpan75 = "case-module--grid-span-7-5--z76zU";
export var gridSpan76 = "case-module--grid-span-7-6--5-+dB";
export var gridSpan77 = "case-module--grid-span-7-7--NbF5g";
export var gridSpan78 = "case-module--grid-span-7-8--pyqLF";
export var gridSpan79 = "case-module--grid-span-7-9--M6AnT";
export var gridSpan810 = "case-module--grid-span-8-10--FHYnI";
export var gridSpan811 = "case-module--grid-span-8-11--6zsxb";
export var gridSpan82 = "case-module--grid-span-8-2--zZq7E";
export var gridSpan83 = "case-module--grid-span-8-3--zEhTr";
export var gridSpan84 = "case-module--grid-span-8-4--NnSIu";
export var gridSpan85 = "case-module--grid-span-8-5--bct+w";
export var gridSpan86 = "case-module--grid-span-8-6--WDA8e";
export var gridSpan87 = "case-module--grid-span-8-7--nW99w";
export var gridSpan88 = "case-module--grid-span-8-8--MEuxs";
export var gridSpan89 = "case-module--grid-span-8-9--ubx9b";
export var gridSpan910 = "case-module--grid-span-9-10--ZSoWB";
export var gridSpan911 = "case-module--grid-span-9-11--O18s2";
export var gridSpan92 = "case-module--grid-span-9-2--iV2NZ";
export var gridSpan93 = "case-module--grid-span-9-3--zEI7t";
export var gridSpan94 = "case-module--grid-span-9-4--mzbiz";
export var gridSpan95 = "case-module--grid-span-9-5--VXOqs";
export var gridSpan96 = "case-module--grid-span-9-6--CGUko";
export var gridSpan97 = "case-module--grid-span-9-7--dQcLw";
export var gridSpan98 = "case-module--grid-span-9-8--xZEil";
export var gridSpan99 = "case-module--grid-span-9-9--8FL-g";
export var textBreak = "case-module--text-break--vADr7";