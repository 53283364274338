import React from "react";

import * as styles from "./CaseStudyArticle.module.scss";

interface CaseStudyArticleProps {
  title: string;
  desc: string;
}

const CaseStudyArticle: React.FC<CaseStudyArticleProps> = ({ title, desc }) => {
  return (
    <div className={styles.CaseStudyArticle}>
      <h4 className={styles.CaseStudyArticle_title}>{title}</h4>
      <p className={styles.CaseStudyArticle_desc}>{desc}</p>
    </div>
  );
};

export default CaseStudyArticle;
